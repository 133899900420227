


































import { UsersModelPath } from '../types'

import { Component, Emit, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import { get } from 'lodash'

@Component({})
export default class AddAdminList extends Vue {
  @Prop() usersPath!:UsersModelPath

  @Emit()
  onAdminRemove(username: string):string {
    return username
  }

  @Emit()
  openModal() {}

  handleCloseClick(username:string):void {
    this.$confirm({
      title: this.$t("remove_user_confirm_title").toString(),
      content: this.$t("remove_user_confirm_text").toString(),
      okText: this.$t('pi_accept').toString(),
      cancelText: this.$t('pi_reject').toString(),
      onOk: () => {
        this.onAdminRemove(username)
      }
    })
  }

  get usersList():Array<string> {
    return get(this.usersPath.model, this.usersPath.key)
  }
}
