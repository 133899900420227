









































import Tags from 'piramis-base-components/src/components/Tags/Tags.vue'
import { TagData } from 'piramis-base-components/src/components/Tags/types'
import HelpMessage from 'piramis-base-components/src/components/HelpMessage/HelpMessage.vue'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'

import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  components: {
    Tags,
    HelpMessage
  },
})
export default class PageTitle extends Vue {
  @Prop({ 'type': Array }) readonly tags!: Array<TagData>

  @Prop({ type: [ Object, Array ] }) readonly translationParams!:Array<any> | Record<string, any>

  @Prop() customHelpMessage!:Vue

  get title(): string | null {
    if (this.$route.name) {
      return this.$t(`${ this.$route.name.toLowerCase() }_page_title`, this.translationParams).toString()
    }

    return null
  }

  get pageHelpMessage(): string | null {
    if (this.$route.name) {
      return this.$te(`${ this.$route.name.toLowerCase() }_page_help_message`) ? this.$t(`${ this.$route.name.toLowerCase() }_page_help_message`, this.$route.params[EntityTypes.CHAT_ID] ? { channel: this.$route.params[EntityTypes.CHAT_ID] } : {}).toString() : null
    }

    return null
  }

  get subtitle(): string | null {
    if (this.$route.name) {
      return this.$te(`${ this.$route.name.toLowerCase() }_page_description`) ? this.$t(`${ this.$route.name.toLowerCase() }_page_description`, this.translationParams).toString() : null
    }

    return null
  }
}
