export default {
  computed  : {
    chat() {
      return this.$store.state.chatState.chat;
    },
    runtime_config() {
      return this.$store.state.AdminTemplate.runtime_config;
    },
    interval_options() {
      return [ {
	label: this.$t("param_hour"),
	value: 60,
      }, {
	label: this.$t("param_day"),
	value: 60 * 24,
      }, {
	label: this.$t("param_three_days"),
	value: 60 * 24 * 3,
      }, {
	label: this.$t("param_week"),
	value: 60 * 24 * 7,
      } ];
    },
    any_message_handler_type() {
      return [
	{
	  label: this.$t("any_message_handler_type_any"),
	  value: "Any",
	}, {
	  label: this.$t("any_message_handler_type_text_caption"),
	  value: "TextCaption",
	}, {
	  label: this.$t("any_message_handler_type_text"),
	  value: "Text",
	},
      ];
    },
    ban_type_4() {
      return [ {
	label: this.$t("param_do_not_punish"),
	value: "none",
      }, {
	label: this.$t("param_remove_from_chat_for_while"),
	value: "ban",
      }, {
	label: this.$t("silent_mode"),
	value: "mute",
      }, {
	label: this.$t("param_remove_from_chat_forever"),
	value: "kick",
      } ];
    },
    ban_type_5() {
      return [ {
	label: this.$t("param_do_not_punish"),
	value: "none",
      }, {
	label: this.$t("param_remove_from_chat_for_while"),
	value: "ban",
      }, {
	label: this.$t("silent_mode"),
	value: "mute",
      }, {
	label: this.$t("param_remove_from_chat_forever"),
	value: "kick",
      }, {
        label: this.$t('mute_media'),
	value: 'mute_media'
      } ];
    },
    exchange_providers() {
      return [ {
	label: this.$t("exchange_provider_bitfinex"),
	value: "BITFINEX",
      }, {
	label: this.$t("exchange_provider_coingecko"),
	value: "COINGECKO",
      } ];
    },
    entertainment_call_enabled_types() {
      return [ {
	label: this.$t("entertainment_call_enabled_none"),
	value: "None",
      }, {
	label: this.$t("entertainment_call_enabled_admins"),
	value: "Admins",
      }, {
	label: this.$t("entertainment_call_enabled_all"),
	value: "All",
      } ];
    },
    command_permissions_list() {
      return [ {
	label: this.$t("command_permissions_list_none"),
	value: "None",
      }, {
	label: this.$t("command_permissions_list_admins"),
	value: "Admins",
      }, {
	label: this.$t("command_permissions_list_all"),
	value: "All",
      } ];
    },
    ban_type_3() {
      return [ {
	label: this.$t("param_do_not_punish"),
	value: "none",
      }, {
	label: this.$t("param_remove_from_chat_forever"),
	value: "ban",
      },
	       ...this.$store.state.chatState.chat.config.facecontrol_username_ban_type === "mute" ? [ {
		 label: this.$t("silent_mode"),
		 value: "mute",
	       } ] : [],
	       {
		 label: this.$t("param_remove_from_chat_for_while"),
		 value: "kick",
	       } ];
    },
    placeholder() { return this.$t("enter_text");},
  },
  methods   : {
    isMobile() {
      return window.mobilecheck();
    },
  },
};
