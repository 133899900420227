



























































































































































import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import PageTitle from '@/components/PageTitle.vue'
import { InputSetups } from '@/mixins/input-setups'
import { UserHelpers } from '@/includes/helpers/UserHelpers'
import AddAdmin from '@/components/AddAdmin/AddAdmin.vue'
import { AddAdminType } from '@/includes/types/AddAdminBy/Enums'

import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import ConfigGroup from 'piramis-base-components/src/components/ConfigGroup/ConfigGroup.vue'
import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import MiniHelpMessage from 'piramis-base-components/src/components/MiniHelpMessage/MiniHelpMessage.vue'
import HighlightAnchor from 'piramis-base-components/src/components/HighlightAnchor.vue'

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  components: {
    AddAdmin,
    ConfigField,
    ConfigGroup,
    CenteredColumnLayout,
    PageTitle,
    MiniHelpMessage,
    HighlightAnchor
  },
  data: () => ({
    UserHelpers,
    AddAdminType
  }),
  computed: {
    $t_users_rights_users_lists() {
      return this.$t('users_rights_users_lists')
    },
    $t_chat_admins() {
      return this.$t('chat_admins')
    },
    $t_chat_admins_help_message() {
      return this.$te('chat_admins_help_message') ? this.$t('chat_admins_help_message') : ''
    },
    $t_chat_admins_short_help_message() {
      return this.$te('chat_admins_short_help_message') ? this.$t('chat_admins_short_help_message') : ''
    },
    $t_chat_admins_good_example_text() {
      return this.$te('chat_admins_good_example_text') ? this.$t('chat_admins_good_example_text') : ''
    },
    $t_chat_admins_bad_example_text() {
      return this.$te('chat_admins_bad_example_text') ? this.$t('chat_admins_bad_example_text') : ''
    },
    $t_config_field_good_example_title() {
      return this.$te('config_field_good_example_title') ? this.$t('config_field_good_example_title') : ''
    },
    $t_config_field_bad_example_title() {
      return this.$te('config_field_bad_example_title') ? this.$t('config_field_bad_example_title') : ''
    }
  },
})
export default class UsersRights extends Mixins(UseFields, InputSetups) {
  get receivedAdministrators(): Array<string> {
    return this.$store.state.chatState.chat.received_administrators
  }
}
