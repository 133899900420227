import { snakeCase } from "lodash";

export default {
  computed: {
    taggedTriggers() {
      const activeChatTriggersIds = this.getActiveChatTriggersIds();
      let activeChatTriggers      = this.getFilteredChatTriggers("active");
      let disabledChatTriggers    = this.getFilteredChatTriggers("disabled");

      activeChatTriggers = activeChatTriggers.slice().map(trigger => {
	return {
	  label: trigger.name,
	  value: trigger.id,
	  tags : [
	    {
	      text : this.$t("option_trigger_active"),
	      color: "rgba(var(--a-success), 1)",
	    },
	  ],
	};
      }).sort((a, b) => {
	if(activeChatTriggersIds.indexOf(a.value) > activeChatTriggersIds.indexOf(b.value)) {
	  return 1;
	} else {
	  return -1;
	}
      });

      disabledChatTriggers = disabledChatTriggers.map(trigger => {
	return {
	  label: trigger.name,
	  value: trigger.id,
	  tags : [
	    {
	      text : this.$t("option_trigger_disabled"),
	      color: "rgba(var(--a-danger), 1)",
	    },
	  ],
	};
      });

      return [
	{ label : this.$t("export_all_triggers"),
	  value: "all",
	},
	...activeChatTriggers,
	...disabledChatTriggers,
      ];
    },
    // используются при выгрузке конфига чата - добавляются тэги
    sortedTriggers() {
      if(this.$store.state.triggersState.frontendTriggers !== null) {
	return this.taggedTriggers
      } else {
	  return this.$store.dispatch('updateTriggers')
	      .then(() => {
		return this.taggedTriggers
	      })
      }

    },
  },
  methods : {
    // используются при выгрузке конфига чата - добавляются тэги
    getSortedTriggerOptions() {
      return new Promise((resolve) => {
	if(this.sortedTriggers.length !== 0) {
	  resolve(this.sortedTriggers);
	} else {
	  resolve([]);
	}
      });
    },
    getActiveChatTriggersIds() {
      return this.$store.state.chatState.chat.triggers.slice();
    },
    getTriggerOptions() {
      return new Promise((resolve) => {
	if(this.$store.state.triggersState.frontendTriggers !== null) {
	  resolve(this.mapTriggerOptions(this.$store.state.triggersState.frontendTriggers));
	} else {
	  this.$store.dispatch('updateTriggers')
	      .then(() => {
		resolve(this.mapTriggerOptions(this.$store.state.triggersState.frontendTriggers))
	      })
	}
      });
    },
    mapTriggerOptions(triggers) {
      let returnTriggers   = [];
      const activeTriggers = this.getActiveChatTriggersIds();

      // TODO нужно ли парсить условия для дескрипшена? видимо, это делалось для SelectPopup, но он не в работе.
      triggers.forEach(trigger => {
	if(activeTriggers.includes(trigger.id)) {
	  let returnTrigger = {
	    label      : trigger.name,
	    value      : trigger.id,
	    description: "",
	  };

	  trigger.conditions.forEach(conditionsGroup => {
	    conditionsGroup.forEach(condition => {

	      returnTrigger.description += `${ this.$t(`trigger_condition_${ snakeCase(condition.type) }`) }: `;

	      if([ "MessageType", "CallerType", "MessageReplyType" ].includes(condition.type)) {
		returnTrigger.description += this.$t(`trigger_condition_${ snakeCase(condition.value[ 0 ]) }`);
	      } else {
		returnTrigger.description += condition.value.join(", ");
	      }

	      returnTrigger.description += "<br/>";
	    });
	  });

	  returnTriggers.push(returnTrigger);
	}
      });

      return returnTriggers;
    },
    getChatFrontendTriggers() {
      const activeTriggersIds = this.getActiveChatTriggersIds();
      if(this.$store.state.triggersState.frontendTriggers) {
	return this.$store.state.triggersState.frontendTriggers
		   .sort((a, b) => {
		     const includesA = activeTriggersIds.includes(a.id);
		     const includesB = activeTriggersIds.includes(b.id);

		     if(includesA !== includesB) {
		       return +includesB - +includesA;
		     }

		     return a.name.localeCompare(b.name);
		   });
      } else {
	return [];
      }
    },
    getFilteredChatTriggers(type) {
      const activeTriggersIds = this.getActiveChatTriggersIds();

      return this.getChatFrontendTriggers().filter(item => {
	if(type === "active") {
	  return activeTriggersIds.includes(item.id);
	}
	if(type === "disabled") {
	  return !activeTriggersIds.includes(item.id);
	}
      });
    },
  },
};
