import Api from "@/includes/logic/Api";
import {
  AddAdminByLinkRequest,
  AddAdminByLinkResponse, UsersResultResponse,
  AdminState,
  BindState,
  FullAddAdminRequest
} from "@/includes/types/AddAdminBy/types";

export class AddAdminService {
  static async addAdminByName(type:'tg', body: FullAddAdminRequest): Promise<{ data: UsersResultResponse }> {
    return await Api.sendRequest(type, 'addadminbyname', body)
  }

  static async deleteAdmin(type: 'tg', body: FullAddAdminRequest): Promise<{ data: UsersResultResponse }> {
    return await Api.sendRequest(type, 'deleteadmin', body)
  }

  static async addAdminByLink(type: 'tg', body: AddAdminByLinkRequest): Promise<{ data: AddAdminByLinkResponse }> {
    return await Api.sendRequest(type, 'addadminbylink', body)
  }

  static async stateAdminByCode(type: 'tg', { token }: AdminState): Promise<{ data: BindState }> {
    return await Api.sendRequest(type, 'stateadminbycode', { token })
  }
}
