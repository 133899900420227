










































































































enum RequestType {
  Link,
  Name
}

import { AddAdminType } from '@/includes/types/AddAdminBy/Enums'
import { InputSetups } from '@/mixins/input-setups'
import { AddAdminService } from '@/includes/Api/AddAdminService'
import {
  AddAdminByLinkRequest,
  AddAdminByLinkResponse, UsersResultResponse,
  FullAddAdminRequest
} from '@/includes/types/AddAdminBy/types'
import { errorNotification, successNotification } from '@/includes/NotificationService'
import AddAdminList from './components/AddAdminList.vue'
import { UsersModelPath } from './types'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import { copyTokenToClipboard } from 'piramis-base-components/src/logic/helpers/copy-to-clipboard'

import { Component, Mixins, Prop } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'

@Component({
  components: {
    AddAdminList
  },
  data() {
    return {
      RequestType
    }
  }
})
export default class AddAdmin extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  @Prop({ type: String, required: true }) addType!: AddAdminType

  @Prop({ type: Object, required: true }) usersPath!: UsersModelPath

  currentAddType: RequestType = RequestType.Name

  modal = false

  requestModel: FullAddAdminRequest | AddAdminByLinkRequest = this.defaultModel()

  checkAddStatusTimeoutId: number | null = null

  tokenShowTo: string | null = null

  deadline = () => new Date().getTime() + 600000

  defaultModel(): FullAddAdminRequest {
    return cloneDeep({
      chat_id: this.$store.state.chatState.chat.chat_id,
      type: this.addType,
      username: ''
    })
  }

  setAddAdminType(type: RequestType): void {
    this.currentAddType = type

    if (type === RequestType.Link) {
      this.$delete(this.requestModel, 'username')
    }
    if (type === RequestType.Name) {
      this.$set(this.requestModel, 'username', '')
    }
  }

  handleAdminByLinkResponse(response: AddAdminByLinkResponse): void {
    const { token, link } = response

    this.tokenShowTo = link

    this.checkAddStatus(token)
  }

  resetStateToDefault(): void {
    this.modal = false
    this.currentAddType = RequestType.Name
    this.requestModel = this.defaultModel()
    this.tokenShowTo = null

    if (this.checkAddStatusTimeoutId) {
      clearTimeout(this.checkAddStatusTimeoutId)
    }
  }

  handleUsers(users: UsersResultResponse['users']): void {
    const usersList = this.getUsersList(users)

    if (usersList) {
      this.updateConfigModel(usersList, this.usersPath)
        .then(() => {
          this.modal = false
          successNotification()
        })
    }
  }

  async checkAddStatus(token: AddAdminByLinkResponse['token']) {
    const { data } = await AddAdminService.stateAdminByCode('tg', { token })

    if (data.binded && data.users && this.checkAddStatusTimeoutId) {
      this.tokenShowTo = null
      clearTimeout(this.checkAddStatusTimeoutId)

      this.handleUsers(data.users)
    } else {
      this.checkAddStatusTimeoutId = setTimeout(async () => {
        await this.checkAddStatus(token)
      }, 3500)
    }
  }

  copyToken(): void {
    copyTokenToClipboard(this.tokenShowTo)
    successNotification(this.$t('copy_link_to_buffer').toString())
  }

  isFullAddAdminStructure(model: FullAddAdminRequest | AddAdminByLinkRequest): model is FullAddAdminRequest {
    return 'username' in model
  }

  onRemoveClick(username: string): void {
    const req = Object.assign({}, this.requestModel, { username })

    AddAdminService.deleteAdmin('tg', req)
      .then(({ data }) => this.handleUsers(data.users))
      .catch(errorNotification)
  }

  async updateConfigModel(newUsers: Array<string>, modelPath: UsersModelPath): Promise<void> {
    if (modelPath && modelPath.key && modelPath.model) {
      const { model, key } = modelPath

      this.$store.commit('pi/EXEC', {
        'key': key,
        'fn': () => {
          this.$set(model, key, newUsers)
        },
      })

      return Promise.resolve()
    }
  }

  getUsersList(users: UsersResultResponse['users']): Array<string> | undefined {
    if (Array.isArray(users)) {
      return users
    }
    if (typeof users === 'object') {
      return Object.values<string>(users)
    }
  }

  makeRequest(): void {
    if (this.currentAddType === RequestType.Name && this.isFullAddAdminStructure(this.requestModel)) {
      AddAdminService.addAdminByName('tg', this.requestModel)
        .then(({ data }) => this.handleUsers(data.users))
        .catch(errorNotification)
    }

    if (this.currentAddType === RequestType.Link) {
      AddAdminService.addAdminByLink('tg', this.requestModel)
        .then(({ data }) => this.handleAdminByLinkResponse(data))
        .catch(errorNotification)
    }
  }
}
