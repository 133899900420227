import { Component, Mixins } from 'vue-property-decorator'
import triggersMixin from "@/mixins/triggersMixin";
import chatConfig from "@/mixins/chatConfig";
import chatPropertiesMixin from "@/mixins/chatPropertiesMixin";
import GroupTopicsMixin from "@/mixins/GroupTopicsMixin";

import { AvailableButtonsTypes } from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/types'
import FingerDownReaction from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/FingerDownReaction'
import Share from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/Share'
import TriggerCall from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/TriggerCall'
import Reaction from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/Reaction'
import PalmsReaction from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/PalmsReaction'
import FingerUpReaction from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/FingerUpReaction'
import Link from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/Link'
import TextButton from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/Text'
import UserPropRedirect from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/UserPropRedirect/index'
import { SelectInputData } from 'piramis-base-components/src/components/Pi/fields/SelectInput/types';
import { FieldData, SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import { TimeUnitInputData, StepUnit } from 'piramis-base-components/src/components/PeriodSimplifier/types'
import { MultiMessageEditorWithMediaData } from 'piramis-base-components/src/components/MultiMessageEditorWithMedia/types'
import { SelectOption } from "piramis-base-components/src/logic/types";
import { FileType } from 'piramis-base-components/src/components/File/types';

@Component({
  'mixins': [ triggersMixin, chatConfig, chatPropertiesMixin ],
  'data': () => ({
    StepUnit
  })
})
export class InputSetups extends Mixins<GroupTopicsMixin>(GroupTopicsMixin) {
  defaultProp = { i18n: this.$i18n }

  get defaultConfig(): Record<string, any> {
    return this.$store.state.AdminTemplate.runtime_config ? this.$store.state.AdminTemplate.runtime_config[ `default_config_${ this.$store.state.chatState.chat.language_code }` ] : {}
  }

  getForms(): Promise<Array<SelectOptionData>> {
    return new Promise((resolve) => {
      this.$store.dispatch("getForms")
          .then(() => {
            const forms: Array<{ name: string, guid: string }>
                = this.$store.state.chatState.questionnaire.forms

            if (!forms || forms.length === 0) {
              resolve([])
            } else {
              resolve(forms.map(form => ({
                    label: form.name,
                    value: form.guid,
                  })))
            }
          });
    })
  }

  getIntervalOptions(): Promise<Array<SelectOptionData>> {
    return Promise.resolve((this as any).interval_options)
  }

  getAnyMessageHandlerType(): Promise<Array<SelectOptionData>> {
    return Promise.resolve((this as any).any_message_handler_type)
  }

  get banTypes():Array<SelectOption> {
    return [ {
      label: this.$t("param_do_not_punish"),
      value: "None",
    }, {
      label: this.$t("param_remove_from_chat_for_while"),
      value: "Ban",
    }, {
      label: this.$t("silent_mode"),
      value: "Mute",
    }, {
      label: this.$t("param_remove_from_chat_forever"),
      value: "Kick",
    } ]
  }

  getBanType4(): Promise<Array<SelectOptionData>> {
    return Promise.resolve((this as any).ban_type_4)
  }

  getCommandPermissionsList(): Promise<Array<SelectOptionData>> {
    return Promise.resolve((this as any).command_permissions_list)
  }

  getExchangeProviderList(): Promise<Array<SelectOptionData>> {
    return Promise.resolve((this as any).exchange_providers)
  }

  getEntertainmentCallEnabledList():Promise<Array<SelectOptionData>> {
    return Promise.resolve((this as any).entertainment_call_enabled_types)
  }

  getBanType3(): Promise<Array<SelectOptionData>> {
    return Promise.resolve((this as any).ban_type_3)
  }

  get triggerCallMessageEditorButton(): TriggerCall {
    return new TriggerCall({ getTriggersFn: (this as any).getTriggerOptions, ...this.defaultProp })
  }

  get userPropRedirectEditorButton(): UserPropRedirect {
    return new UserPropRedirect({ getPropertiesFn: (this as any).getChatPropertiesSelectOptions, ...this.defaultProp })
  }

  get defaultMessageEditorButtons(): AvailableButtonsTypes {
    return [
      ...this.$store.state.chatState.chat.version >= 31000 ? [
          this.userPropRedirectEditorButton,
      ] : [],
      this.triggerCallMessageEditorButton,
      new Share(this.defaultProp),
      new Reaction(this.defaultProp),
      new PalmsReaction(this.defaultProp),
      new FingerUpReaction(this.defaultProp),
      new FingerDownReaction(this.defaultProp),
      new Link(this.defaultProp),
    ]
  }

  get defaultChatMessageEditorButtons(): AvailableButtonsTypes {
    return [ new TextButton(this.defaultProp) ]
  }

  get groupUploadTarget() {
    return { target: 'target', target_id: JSON.stringify({ type: 'group', id: this.$store.state.chatState.chat.chat_id }) }
  }

  boardUploadTarget(token:string) {
    return  { target: 'target', target_id: JSON.stringify({ type: 'board', token }) }
  }

  get stateChatConfigPath():any {
    return this.$store.state.chatState.chat.config
  }

  get stateChatPath():any {
    return this.$store.state.chatState.chat
  }

  get defaultMessageEditorSettingsStructure() {
    return {
      'remove_after': 0,
      'send_after': 0,
      'pin': false,
      'remove_previous': false,
      'disable_link_preview': false,
      'disable_notify': false,
      'protect_content': false,
      'topics': []
    }
  }

  configInputSetup(args: FieldData): FieldData {
    if (this.defaultConfig) {
      const defaultValue = this.defaultConfig[args.key]
      if (args.defaultValue === undefined && defaultValue !== undefined) {
        args.defaultValue = defaultValue
      }
    }

    if ([ 'hello_message', 'close_chat_message', 'open_chat_message', 'admin_anti_karma_message' ].includes(args.key)) {
      const multiEditorInputData: any = args as FieldData & MultiMessageEditorWithMediaData

      this.setEditorTopics(multiEditorInputData)

      multiEditorInputData.settingsStructure = this.defaultMessageEditorSettingsStructure
    }

    if ([ 'first_inactive_kick_warn1', 'first_inactive_kick_warn2', 'inactive_kick_warn1', 'inactive_kick_warn2',
      'hello_message', 'leaveMessage', 'wakeup_message',
      'admin_message', 'rules', 'facecontrol_warning_message', 'facecontrol_ban_message',
      'mute_command_text', 'ban_command_text', 'kick_command_text', 'karma_message', 'anti_karma_message',
      'admin_karma_message', 'reputation_overtake_message', 'referral_private_message', 'xp_action_message',
      'xp_action_jack_pot_message', 'start_bonus_xp_message', 'end_bonus_xp_message',
      'toprepmessage', 'bottomrepmessage', 'topapmessage', 'bottomapmessage', 'topmsg', 'bottommsg',
      'topxp', 'bottomxp', 'warning_message', 'ban_message', 'leave_member_message'
    ].includes(args.key)) {
      const multiEditorInputData: any = args as FieldData & MultiMessageEditorWithMediaData

      multiEditorInputData.availableButtonsTypes = this.defaultMessageEditorButtons
      multiEditorInputData.blacklistMediaButtonTypes = [ FileType.MessagePreview ]

      this.setEditorTopics(multiEditorInputData)

      multiEditorInputData.settingsStructure = this.defaultMessageEditorSettingsStructure

      if ([ 'wakeup_message', 'admin_message' ].includes(args.key)) {
        multiEditorInputData.settingsStructure = {
          'remove_after': 0,
          'pin': false,
          'disable_link_preview': false,
          'disable_notify': false,
          'remove_previous': false,
          'protect_content': false,
          'topics': [],
          'blacklistMediaButtonTypes': [ FileType.MessagePreview ]
        }
      }
    }

    // chat_buttons_message
    if ([ 'chat_buttons_message' ].includes(args.key)) {
      const multiEditorInputData: any = args as FieldData & MultiMessageEditorWithMediaData
      multiEditorInputData.availableButtonsTypes = this.defaultChatMessageEditorButtons

      this.setEditorTopics(multiEditorInputData)

      multiEditorInputData.settingsStructure = this.defaultMessageEditorSettingsStructure
      multiEditorInputData.blacklistMediaButtonTypes = [ FileType.MessagePreview ]
    }

    if ([
      'after_leave_trigger',
      'after_greeting_check_trigger',
      'after_not_first_greeting_trigger_new', 'after_not_first_greeting_trigger',
      'after_first_greeting_trigger_new', 'after_first_greeting_trigger',
      'after_greeting_trigger_new', 'after_greeting_trigger',
      'referral_not_first_private_trigger_new', 'referral_not_first_private_trigger',
      'referral_private_trigger_new', 'referral_private_trigger',
      'referral_first_private_trigger_new', 'referral_first_private_trigger'
    ].includes(args.key)) {
      const selectInputData: SelectInputData = args as SelectInputData
      if (selectInputData.options === undefined) {
        selectInputData.options = (this as any).getTriggerOptions
      }
    }

    if ([ 'enter_protection_form' ].includes(args.key)) {
      const selectInputData: SelectInputData = args as SelectInputData
      if (selectInputData.options === undefined) {
        selectInputData.options = this.getForms
      }
    }

    if ([ 'ban_unverified_users_time', 'ban_inout_users_time', 'inactive_kick_interval', 'first_inactive_kick_interval',
      'new_user_limit', 'liven_up_timeout', 'admin_message_interval' ].includes(args.key)) {
      const timeUnitInputData: TimeUnitInputData = args as TimeUnitInputData
      timeUnitInputData.units = [ StepUnit.Minute, StepUnit.Hour, StepUnit.Day ]
    }

    if ([ 'reputation_min_interval', 'chat_reputation_min_interval' ].includes(args.key)) {
      const timeUnitInputData: TimeUnitInputData = args as TimeUnitInputData
      timeUnitInputData.units = [ StepUnit.Second, StepUnit.Minute, StepUnit.Hour, StepUnit.Day ]
    }

    if (args.setter === undefined) {
      args.setter = (value: any): void => {
        this.$store.commit('pi/EXEC', {
          'key': args.key,
          'fn': () => {
            this.$set(args.model, args.key as string, value)
          },
        })
      }
    }

    return args
  }
}
